import {Injectable} from '@angular/core';
import {CanDeactivate, CanDeactivateFn, Router as NativeRouter} from '@angular/router';
import { ActivatedRouteSnapshot, NavigationStart, RouterStateSnapshot } from '@angular/router';
import {Observable} from 'rxjs';
import {isConfirmed} from 'src/app/domain/function/type.helper';
import AuthApi from 'src/app/domain/service/api/auth.api';
import OnboardingRouteResolver from 'src/app/domain/service/route-resolver/onboarding-route-resolver.service';
import {ConfirmRepeatKycModal} from 'src/app/infrastructure/modal/confirm-repeat-kyc.modal';
import {Router} from 'src/app/infrastructure/router/router';
import {LeadKycService} from 'src/app/infrastructure/service/contracting/kyc/lead-kyc.service';
import {Modal} from 'src/app/infrastructure/service/modal.service';
import {WebPage} from 'src/app/ui/webapp/page/web.page';

// @ts-ignore
@Injectable({
  providedIn: 'root'
})
export class IsNavigableBackGuard {

  pressButtonBack = false;
  private authApi: AuthApi;

  constructor(
    authApi: AuthApi,
    private router: Router,
    private nativeRouter: NativeRouter,
    private modal: Modal,
    private kycService: LeadKycService,
    protected onboardingRouteResolver: OnboardingRouteResolver,
  ) {
    this.authApi = authApi;
    this.nativeRouter.events
    .subscribe((event: NavigationStart) => {
      if (event.navigationTrigger === 'popstate') {
        this.pressButtonBack = true;
        return;
      }
    });
  }

  canDeactivate: CanDeactivateFn<WebPage> = (component: WebPage, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot) => {
    if (this.pressButtonBack) {
      this.pressButtonBack = false;
      return this.goBack();
    }
    return component.canDeactivate();
  };

  private async goBack() {
    const modal = new ConfirmRepeatKycModal(
      this.modal,
      {},
      {cssClass: 'modal-size-medium'}
    );
    await modal.present();
    const confirmOption = await modal.onDidDismiss();
    if (isConfirmed(confirmOption)) {
      await this.kycService.createNewKyc();
      await this.router.navigateByUrl(this.onboardingRouteResolver.resolveMain());
      return true;
    }
    return false;
  }
}
