import {uuid} from '../function/uuid.helper';
import {Onboarding} from '../model/onboarding.model';
import {Command} from './command.command';

export interface SetDataFilled extends Command {
  commandId: string;
  id: string;
  dataFilledAt: string;
}

export interface SetChildDataFilled extends Command {
  commandId: string;
  id: string;
  childDataFilledAt: string;
}

export interface SetIbanFilled extends Command {
  commandId: string;
  id: string;
  ibanFilledAt: string;
}

export interface SetDocumentsSigned extends Command {
  commandId: string;
  id: string;
  documentsSignedAt: string;
}

export interface SetVatUploaded extends Command {
  commandId: string;
  id: string;
  vatUploadedAt: string;
}

export interface SetChildVatUploaded extends Command {
  commandId: string;
  id: string;
  childVatUploadedAt: string;
}

export interface SetChildRelationshipProofUploaded extends Command {
  commandId: string;
  id: string;
  childRelationshipProofUploadedAt: string;
}

export interface SetProductSelected extends Command {
  commandId: string;
  id: string;
  productSelectedAt: string;
  planConfirmedAt: string;
}

export interface SetProfiledAt extends Command {
  commandId: string;
  id: string;
  profiledAt: string;
  productSelectedAt?: string;
  phoneConfirmedAt?: string;
  emailConfirmedAt?: string;
  dataFilledAt?: string;
  vatUploadedAt?: string;
}

export interface ResetProfiledAt extends Command {
  commandId: string;
  id: string;
  profiledAt: string;
  productSelectedAt: string;
}

export interface SetPlanConfirmedAt extends Command {
  commandId: string;
  id: string;
  planConfirmedAt: string;
}

export interface SetEmailConfirmedAt extends Command {
  commandId: string;
  id: string;
  emailConfirmedAt: string;
}

export interface SetPhoneConfirmedAt extends Command {
  commandId: string;
  id: string;
  phoneConfirmedAt: string;
}

export const createDataFilledCommand = (onboarding: Onboarding): SetDataFilled => ({
  commandId: uuid(),
  id: onboarding.id,
  dataFilledAt: 'now'
});

export const createChildDataFilledCommand = (onboarding: Onboarding): SetChildDataFilled => ({
  commandId: uuid(),
  id: onboarding.id,
  childDataFilledAt: 'now'
});

export const createProductSelectedCommand = (onboarding: Onboarding): SetProductSelected => ({
  commandId: uuid(),
  id: onboarding.id,
  productSelectedAt: 'now',
  planConfirmedAt: ''
});

export const createSetProfiledAtCommand = (onboarding: Onboarding): SetProfiledAt => ({
  commandId: uuid(),
  id: onboarding.id,
  profiledAt: 'now'
});

export const createResetProfiledAtCommand = (onboarding: Onboarding): ResetProfiledAt => ({
  commandId: uuid(),
  id: onboarding.id,
  profiledAt: '',
  productSelectedAt: ''
});

export const createSetPlanConfirmedAtCommand = (onboarding: Onboarding): SetPlanConfirmedAt => ({
  commandId: uuid(),
  id: onboarding.id,
  planConfirmedAt: 'now'
});

export const createSetEmailConfirmedAtCommand = (onboarding: Onboarding): SetEmailConfirmedAt => ({
  commandId: uuid(),
  id: onboarding.id,
  emailConfirmedAt: 'now'
});

export const createSetPhoneConfirmedAtCommand = (onboarding: Onboarding): SetPhoneConfirmedAt => ({
  commandId: uuid(),
  id: onboarding.id,
  phoneConfirmedAt: 'now'
});

export const createSetVatUploadedCommand = (onboarding: Onboarding): SetVatUploaded => ({
  commandId: uuid(),
  id: onboarding.id,
  vatUploadedAt: 'now'
});

export const createSetChildVatUploadedCommand = (onboarding: Onboarding): SetChildVatUploaded => ({
  commandId: uuid(),
  id: onboarding.id,
  childVatUploadedAt: 'now'
});

export const createSetChildRelationshipProofUploadedCommand = (onboarding: Onboarding): SetChildRelationshipProofUploaded => ({
  commandId: uuid(),
  id: onboarding.id,
  childRelationshipProofUploadedAt: 'now'
});

export const createSetIbanFilled = (onboarding: Onboarding): SetIbanFilled => ({
  commandId: uuid(),
  id: onboarding.id,
  ibanFilledAt: 'now'
});

export const createSetDocumentsSigned = (onboarding: Onboarding): SetDocumentsSigned => ({
  commandId: uuid(),
  id: onboarding.id,
  documentsSignedAt: 'now'
});
