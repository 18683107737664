import {cloneDeep, isNil, some} from 'lodash';
import {SortFn} from 'src/app/domain/function/type.helper';

export const mergeArrays = <T>(originalArray: T[], arrayToMerge: T[]): T[] => {
  if (!originalArray)
    {originalArray = [];}

  let newArray = [...originalArray];

  arrayToMerge.forEach((element: T) => {
    newArray = pushOrReplace(newArray, element);
  });

  return newArray;
};

export const pushOrReplace = <T>(array: T[], entry: T): T[] => {
  const genericEntry: any = entry;
  let newArray = cloneDeep(array);

  if (some(newArray, (node: any) => node.id === genericEntry.id))
    {newArray = newArray.map((node: any) => (node.id === genericEntry.id) ? entry : node);}
  else
    {newArray.push(entry);}

  return newArray;
};

export const range = (start: number, end: number): number[] =>
  Array.from({length: end - start + 1}).map((el, i) => i + start);

export const sortAsc: SortFn = (a, b) => a > b ? 1 : (a < b ? -1 : 0);
export const sortDesc: SortFn = (a, b) => sortAsc(a, b) * -1 as any;

export const arrayToObject = (array: any[], prefix: string, indexStart?: number) => (array.reduce((a,b,i) => ({...a, [prefix+(i+(indexStart?? 1))]: b}), {}));

export const includesSome = (haystack: string[], arr: string[]): boolean => arr.some((v) => haystack.indexOf(v) >= 0);
