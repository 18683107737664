import {EventEmitter} from '@angular/core';
import {ConfirmOption} from 'src/app/domain/function/type.helper';
import {AbstractModal} from 'src/app/infrastructure/modal/abstract.modal';
import {ConfirmRepeatKycComponent} from 'src/app/ui/webapp/component/kyc/confirm-repeat-kyc/confirm-repeat-kyc.component';

export class ConfirmRepeatKycModal extends AbstractModal<ConfirmRepeatKycComponent, ConfirmOption> {

  protected modalFromComponent() {
    return ConfirmRepeatKycComponent;
  }

  protected defaultValueOnDismiss(): ConfirmOption {
    return 'dismiss';
  }

  protected async onPresent(): Promise<void> {
    await super.onPresent();
    this.subscribe(this.componentProps.chosenOption, (option: ConfirmOption) => this.dismiss(option));
  }

  protected init(): void {
    super.init();
    this.componentProps.chosenOption ??= new EventEmitter<ConfirmOption>();
  }
}
