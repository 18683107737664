export type PropsOf<T> = { [Property in keyof T]?: T[Property]; };
export type ClassOf<T> = new(...args: any[]) => T;
export type Subscription = { unsubscribe: () => void };
export type Subscribeable<T> = { subscribe: (next: (value?: T) => void) => Subscription };
export type Fn<T = any, A extends Array<any> = []> = (...args: A) => T;
export type AsyncFn<T = any, A extends Array<any> = []> = Fn<Promise<T>, A>;
export type SortFn<T = any> = (a: T, b: T) => (-1 | 0 | 1);
export type ConfirmOption = 'confirm' | 'dismiss';
export type Index<T> = { [k: string | number]: T };

export const isConfirmed = (option: ConfirmOption): boolean => option === 'confirm';
