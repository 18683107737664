<div class="flex justify-end p-2 z-10">
  <ion-icon class="text-2xl" name="close" (click)="chosenOption.emit('dismiss')"></ion-icon>
</div>
<div class="flex flex-col absolute top-4 z-0">
  <app-section>
    <div class="text-center px-4">
      <h1 class="pb-4">{{'shared.attention' | translate}}</h1>
      <p class="py-4">{{'contracting.kyc.confirmRepeat.description' | translate}}</p>
      <p class="pb-4 font-bold">{{'contracting.kyc.confirmRepeat.question' | translate}}</p>
      <app-filled-button (click)="chosenOption.emit('confirm')">
        {{ 'shared.yesContinue' | translate }}
      </app-filled-button>
    </div>
  </app-section>
</div>

