import { Directive, HostListener, Input } from '@angular/core';
import {IsNavigableBackGuard} from 'src/app/ui/webapp/page/onboarding/guard/is-navigate-back.guard';

@Directive({
  standalone: true,
  selector: '[appResetBackState]'
})
export class ResetBackStateDirective {
  constructor(private backGuard: IsNavigableBackGuard) {}

  @HostListener('click', ['$event'])
  onClick() {
    this.backGuard.pressButtonBack = false;
  }
}
