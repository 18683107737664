import {isNil} from 'lodash';
import {ProductId} from 'src/app/domain/model/product/product.model';

export interface ProductSettings {
  id: ProductId;
}

export interface PensionPlanOrigin {
  pensionPlanId: string;
}

export interface PlanSettings {
  expectedYears: number;
  fundsOrigin: string | null;
  iban: string | null;
  initialAmount: number;
  monthlyAmount: number;
  isForChild: boolean;
  productSettings: ProductSettings | null;
  product: ProductId;
  isTransfer: boolean;
  pensionPlanOrigin: PensionPlanOrigin | null;
  portfolioMaster: string | null;
  authSignatureContract: string | null;
}

export const createEmptyPlanSettings = (): PlanSettings => ({
  expectedYears: null,
  fundsOrigin: null,
  iban: null,
  initialAmount: 0,
  monthlyAmount: 0,
  isForChild: false,
  productSettings: null,
  product: null,
  isTransfer: false,
  pensionPlanOrigin: null,
  portfolioMaster: null,
  authSignatureContract: null,
});

export const configurePlanSettingsAmount = (
  planSettings: PlanSettings,
  isTransfer: boolean,
  initialAmount: number,
  monthlyAmount: number,
  pensionPlanOriginId?: string
): PlanSettings => {
  if (!isTransfer) {
    planSettings.isTransfer = false;
    planSettings.pensionPlanOrigin = null;
    planSettings.initialAmount = initialAmount;
    planSettings.monthlyAmount = monthlyAmount;
  }

  if (isTransfer) {
    planSettings.isTransfer = true;
    planSettings.pensionPlanOrigin = {
      pensionPlanId: pensionPlanOriginId
    };
    planSettings.initialAmount = 0;
    planSettings.monthlyAmount = monthlyAmount;
  }

  return planSettings;
};

export const isPlanSettingsForChild = (planSettings: PlanSettings): boolean  => planSettings?.isForChild;

export const isPlanSettingsAmountConfigured = (planSettings: PlanSettings): boolean => {
  if (planSettings.isTransfer) {
    return !isNil(planSettings.pensionPlanOrigin?.pensionPlanId)
      && planSettings.initialAmount === 0
      && planSettings.monthlyAmount >= 0;
  }

  return isNil(planSettings.pensionPlanOrigin?.pensionPlanId)
    && planSettings.initialAmount > 0
    && planSettings.monthlyAmount >= 0;
};

export const updatePlanSettingsMasterPortfolio = (planSettings: PlanSettings, portfolioMaster: string): PlanSettings  => {
  planSettings.portfolioMaster = portfolioMaster;
  return planSettings;
};

export const resetPlanSettingsAmount = (planSettings: PlanSettings): PlanSettings => configurePlanSettingsAmount(planSettings, false, 0, 0);
