import {WebComponent} from 'src/app/ui/webapp/component/web.component';
import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-section',
  template: `
      <div class="w-full">
          <app-separator *ngIf="title" [title]="title"></app-separator>
          <app-content-width [full]="full">
              <ng-content></ng-content>
          </app-content-width>
      </div>
  `
})
export class SectionComponent extends WebComponent {
  @Input() public title: string | null = null;
  @Input() public full = false;
}
