import {merge} from 'lodash';
import environmentDefaults from 'src/environments/environment.defaults';

const APP_ENV = 'staging-evo';

export const environment = merge(environmentDefaults, {
  env: APP_ENV,
  rollbar: {
    environment: APP_ENV
  },
  recaptchaSiteKey: '6LcQbnwqAAAAADfxPRnBXop6_4TnTq69TF7GyKAW',
  production: false,
  hostName: 'staging.evo.app.finizensdev.com',
  apiHost: 'https://evo-staging.api.finizens.com',
  langs: ['es'],
  partner: {
    id: 'evo',
    isExternal: true,
    appName: 'Staging Evo',
    name: 'EVO Banco',
    webUrl: null,
    investmentPlan: {
      minimumAmountToBeginToInvest: 1000,
      minimumAmountForNextRebalances: 300,
      amountToBePremium: 100000
    },
    onlyCommercialization: true,
    canFinizensSuggestOptimizationRecommendations: false,
    providesIbanData: true,
    login: {
      provider: 'external',
      type: 'sso'
    },
    faqs: {
      url: 'https://www.evobanco.com/ayuda/',
    },
    contact: {
      phone: {
        countryPrefix: '0034',
        phoneNumber: '910900900',
        openingSchedule: {
          openingTime: '08:00',
          closingTime: '22:00',
          fromWeekday: 'monday',
          toWeekday: 'friday',
          includesLegalHolidays: false
        },
        callFromAbroad: false
      },
      email: 'plandeInversionInteligente.ayuda@evobanco.com.staging'
    },
    kycPotentialAmountQuestions: false,
    partnerProvidesUserData: true,
    informedSale: true
  },
  fixtures: {
    enabled: true,
    externalLogin: {
      port: null
    }
  },
  analytics: {
    segmentWriteKey: 'BepVsMM7SKldYbIsLxMh3bCDpdL3QuW0',
  },
  affiliates: {
    financeAds: {
      enable: false
    },
    facebookPixel: {
      enable: false
    }
  },
  legal: {
    privacyPolicyUrl: '',
    legalWarningUrl: '',
  },
  layout: {
    showFooter: true,
    showHereWeExplainItInDetail: false,
    showLink: true,
    showPoweredBy: true,
    showLogout: false,
    footer: {
      show: true,
      type: 'tiny'
    },
  },
  products: {
    investment: null,
    pensionPlan: null,
    unitLinked: {
      name: 'Plan de Inversión Inteligente',
      productId: 'evo_caser_unit_linked',
      isManagedInThisEnvironment: true,
      showFeesMenuButton: false,
      amount: {
        default: {
          minInitial: 50,
          maxInitial: 10000000,
          minMonthly: 30,
          maxMonthly: 1000000,
          recommendedInitial: 50,
          recommendedExtraordinary: 5000,
          recommendedMonthly: 30,
          insuranceBenefitAmount: 500,
        }
      },
      custodians: {
        name: 'Caser Seguros',
        default: {
          deposit: {
            canDisableMonthly: true,
            minimumAmount: 50,
            annualMaximum: 10000000 // Máximo anual de un UL, basado en que se permiten aportaciones de 1.000.00€ al mes
          },
          limits: {
            lastDayToApplyAChangeOfDebitReceiptInFollowingMonth: 15
          }
        }
      }
    },
  },
  fees: {
    feeReduction: 0.14
  },
  shareScreen: {
    enable: true,
    groupid: '20435',
    site: 'staging'
  },
  // warning: use absolute url
  welcomeUrl: '/onboarding/evo/welcome',
  allowedPhonePrefixes: []
});
