import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiClient} from '../../../infrastructure/http/api.client';
import {Onboarding} from '../../model/onboarding.model';
import OnboardingStore from '../../store/onboarding.store';
import {objKeysToSnakeCase} from '../../function/object.helper';
import {filter, tap} from 'rxjs/operators';
import {SetChildDataFilled, SetDataFilled, SetProductSelected, SetChildRelationshipProofUploaded, SetVatUploaded, SetIbanFilled, SetProfiledAt, SetPlanConfirmedAt, SetEmailConfirmedAt, SetPhoneConfirmedAt, SetDocumentsSigned, SetChildVatUploaded} from '../../command/onboarding.command';
import {AppEnvironmentService} from 'src/app/infrastructure/environment/app-environment/app-environment.service';
import PartnerCommandHidratorService from 'src/app/domain/service/api/partner/partner-command-hidrator.api';

@Injectable({
  providedIn: 'root',
})
export default class OnboardingApi {

  private readonly url = 'onboarding';

  constructor(
    private api: ApiClient,
    private onboardingStore: OnboardingStore,
    private partnerCommandHidrator: PartnerCommandHidratorService,
    private appEnvironment: AppEnvironmentService,
  ) {
  }

  public onboarding(): Observable<Onboarding> {
    return this.onboardingStore.onboarding$.pipe(
      tap(stored => {
        if (!stored) {
          this.fetch();
        }
      }),
      filter(stored => !!stored)
    );
  }

  public async fetch(): Promise<void> {
    const onboarding: Onboarding = await this.api.get(this.url);
    this.onboardingStore.saveOnboarding(onboarding);
  }

  public syncOnboarding(): Onboarding | null {
    return this.onboardingStore.syncOnboarding();
  }

  public async setProductSelected(command: SetProductSelected): Promise<void> {
    const snakeCommand: object = objKeysToSnakeCase(command);
    await this.api.patch(this.url + '/' + command.id, snakeCommand);
    await this.fetch();
  }

  public async setProfiledAt(command: SetProfiledAt): Promise<void> {
    if (this.appEnvironment.isAppForThirdPartners()) {
      command = this.partnerCommandHidrator.hidrateSetProfiledAt(command);
    }
    const snakeCommand: object = objKeysToSnakeCase(command);
    await this.api.patch(this.url + '/' + command.id, snakeCommand);
    await this.fetch();
  }

  public async setPlanConfirmedAt(command: SetPlanConfirmedAt): Promise<void> {
    const snakeCommand: object = objKeysToSnakeCase(command);
    await this.api.patch(this.url + '/' + command.id, snakeCommand);
    await this.fetch();
  }

  public async setEmailConfirmedAt(command: SetEmailConfirmedAt): Promise<void> {
    const snakeCommand: object = objKeysToSnakeCase(command);
    await this.api.patch(this.url + '/' + command.id, snakeCommand);
    await this.fetch();
  }

  public async setPhoneConfirmedAt(command: SetPhoneConfirmedAt): Promise<void> {
    const snakeCommand: object = objKeysToSnakeCase(command);
    await this.api.patch(this.url + '/' + command.id, snakeCommand);
    await this.fetch();
  }

  public async setDataFilled(command: SetDataFilled): Promise<void> {
    const snakeCommand: object = objKeysToSnakeCase(command);
    await this.api.patch(this.url + '/' + command.id, snakeCommand);
    await this.fetch();
  }

  public async setChildDataFilled(command: SetChildDataFilled): Promise<void> {
    const snakeCommand: object = objKeysToSnakeCase(command);
    await this.api.patch(this.url + '/' + command.id, snakeCommand);
    await this.fetch();
  }

  public async setVatUploaded(command: SetVatUploaded): Promise<void> {
    const snakeCommand: object = objKeysToSnakeCase(command);
    await this.api.patch(this.url + '/' + command.id, snakeCommand);
    await this.fetch();
  }

  public async setChildVatUploaded(command: SetChildVatUploaded): Promise<void> {
    const snakeCommand: object = objKeysToSnakeCase(command);
    await this.api.patch(this.url + '/' + command.id, snakeCommand);
    await this.fetch();
  }

  public async setRelationshipProofUploaded(command: SetChildRelationshipProofUploaded): Promise<void> {
    const snakeCommand: object = objKeysToSnakeCase(command);
    await this.api.patch(this.url + '/' + command.id, snakeCommand);
    await this.fetch();
  }

  public async setIbanFilled(command: SetIbanFilled): Promise<void> {
    const snakeCommand: object = objKeysToSnakeCase(command);
    await this.api.patch(this.url + '/' + command.id, snakeCommand);
    await this.fetch();
  }

  public async setDocumentsSigned(command: SetDocumentsSigned): Promise<void> {
    const snakeCommand: object = objKeysToSnakeCase(command);
    await this.api.patch(this.url + '/' + command.id, snakeCommand);
    await this.fetch();
  }

  public async waitFetchAndSync(): Promise<Onboarding> {
    let onboarding = this.syncOnboarding();
    if (!onboarding) {
      await this.fetch();
      onboarding = this.syncOnboarding();
    }
    return onboarding;
  }
}
