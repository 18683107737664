import {Injectable} from '@angular/core';
import CustomerPortfolioStore from 'src/app/domain/store/customer-portfolio.store';
import CustomerStore from 'src/app/domain/store/customer/customer.store';
import KycStepStore from 'src/app/domain/store/kyc-step.store';
import KycStore from 'src/app/domain/store/kyc.store';
import LeadStore from 'src/app/domain/store/lead.store';
import OnboardingStore from 'src/app/domain/store/onboarding.store';
import {RouterStorageService} from 'src/app/infrastructure/service/router-storage.service';

@Injectable({
  providedIn: 'root',
})
export class StoreReseter {
  constructor(
    private kycStepStore: KycStepStore,
    private onboardingStore: OnboardingStore,
    private kycStore: KycStore,
    private customerStore: CustomerStore,
    private portfolioStore: CustomerPortfolioStore,
    private leadStore: LeadStore,
    private routerStorage: RouterStorageService,
  ) {
  }

  public reset() {
    this.kycStore.reset();
    this.kycStepStore.reset();
    this.onboardingStore.reset();
    this.customerStore.reset();
    this.portfolioStore.reset();
    this.leadStore.reset();
    this.routerStorage.reset();
  }

  public resetProfiling() {
    this.kycStepStore.reset();
    this.kycStore.reset();
  }
}
