import {Injectable} from '@angular/core';
import {Localstorage} from 'src/app/domain/service/storage/localstorage';

export const vatFaqBackPage = 'back_page_customer_vat';
export const configureAmountBackPage = '__back_page_configure_amount__';

@Injectable({
  providedIn: 'root',
})
export class RouterStorageService  {
  constructor(private readonly localStorage: Localstorage) {
  }

  saveRoute(name: string,route: string): void {
    this.localStorage.syncSet(name, route);
  }

  getRoute(name: string): string {
    return this.localStorage.syncGet(name);
  }

  popRoute(name: string): string {
    const route = this.localStorage.syncGet(name);
    this.localStorage.unset(name);
    return route;
  }

  reset(): void {
    this.localStorage.unset(vatFaqBackPage);
    this.localStorage.unset(configureAmountBackPage);
  }
}
