import {WebComponent} from 'src/app/ui/webapp/component/web.component';
import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-content-width',
  template: `
      <div class="w-full">
          <div class='mx-auto' [ngClass]="{ 'w-[90%] sm:w-3/4': false === full }">
              <ng-content></ng-content>
          </div>
      </div>
  `
})
export class ContentWidthComponent extends WebComponent {
  @Input() public full = false;
}
