import {Injectable} from '@angular/core';
import {createResetProfiledAtCommand} from 'src/app/domain/command/onboarding.command';
import KycApi from 'src/app/domain/service/api/kyc.api';
import OnboardingApi from 'src/app/domain/service/api/onboarding.api';
import {KycService} from 'src/app/infrastructure/service/contracting/kyc/kyc.service';
import {StoreReseter} from 'src/app/infrastructure/service/reset-store';

@Injectable({providedIn: 'root'})
export class LeadKycService implements KycService {

  constructor(
    private storeReseter: StoreReseter,
    private kycApi: KycApi,
    private onboardingApi: OnboardingApi
  ) {}

  async createNewKyc(): Promise<void> {
    const onboarding = await this.onboardingApi.waitFetchAndSync();
    await this.onboardingApi.setProfiledAt(createResetProfiledAtCommand(onboarding));
    this.storeReseter.resetProfiling();
    await this.kycApi.fetch();
  }
}
