import {Component, Input} from '@angular/core';
import {WebComponent} from 'src/app/ui/webapp/component/web.component';

@Component({
  selector: 'app-separator',
  template: `
      <div class="w-full py-3 my-5 text-sm text-separator bg-separator-light">
          <app-content-width [full]="false">
              <span class="uppercase">{{title}}</span>
          </app-content-width>
      </div>
  `
})
export class SeparatorComponent extends WebComponent {
  @Input() public title: string;
}
