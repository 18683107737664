/* eslint-disable no-underscore-dangle */
import {defer} from 'lodash';
import {ClassOf, PropsOf, Subscribeable, Subscription} from 'src/app/domain/function/type.helper';
import {uuid} from 'src/app/domain/function/uuid.helper';
import {Modal} from 'src/app/infrastructure/service/modal.service';

export interface ModalOptions {
  cssClass?: string | string[];
  breakpoints?: number[];
  initialBreakpoint?: number;
  animated?: boolean;
  swipeToClose?: boolean;
  keyboardClose?: boolean;
}

export abstract class AbstractModal<T, D> {
  public readonly id: string;
  public readonly componentClass: ClassOf<T>;
  private ionModal: HTMLIonModalElement;
  private subscriptions: Subscription[] = [];

  public constructor(
    private readonly modalService: Modal,
    public readonly componentProps?: PropsOf<T>,
    public readonly options?: ModalOptions
  ) {
    this.id = uuid();
    this.componentClass = this.modalFromComponent();
    this.componentProps ??= {};
    this.options ??= {};
    this.init();
  }

  readonly present = async (): Promise<void> => {
    this.ionModal = await this.modalService.present({
      component: this.componentClass,
      componentProps: this.componentProps,
      ...this.options
    });
    await this.onPresent();
  };

  readonly dismiss = async (data: D): Promise<void> => {
    await this.modalService.dismiss(data);
    this.ionModal = null;
  };

  readonly onDidDismiss = async (): Promise<D> => {
    const {data} = await this.ionModal.onDidDismiss();
    return undefined === data ? this.defaultValueOnDismiss() : data;
  };

  protected defaultValueOnDismiss(): D {
    return undefined;
  }

  protected async onPresent(): Promise<void> {
    defer(async () => await this.onDismiss(await this.onDidDismiss()));
  }

  protected async onDismiss(data: D): Promise<void> {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.subscriptions = [];
  }

  protected init(): void {
    this.options.breakpoints ??= [0.1, 0.5, 1];
    this.options.cssClass ??= 'p-5 rounded bg-black-100';
  }

  protected subscribe<S>(subscribeable: Subscribeable<S>, fn: (value: S) => void): void {
    this.subscriptions.push(subscribeable.subscribe(fn));
  }

  protected abstract modalFromComponent(): ClassOf<T>;
}
