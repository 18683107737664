import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {filter, tap} from 'rxjs/operators';
import {sortDesc} from 'src/app/domain/function/array.helper';
import {ApiClient} from 'src/app/infrastructure/http/api.client';
import {SignKyc, UpdateKyc} from 'src/app/domain/command/kyc.command';
import {objKeysToSnakeCase} from 'src/app/domain/function/object.helper';
import {KycAnswer} from 'src/app/domain/model/kyc/kyc-answer';
import {Kyc} from 'src/app/domain/model/kyc/kyc.model';
import KycStore from 'src/app/domain/store/kyc.store';

@Injectable({
  providedIn: 'root'
})
export default class KycApi {

  private readonly url = 'kyc';

  constructor(private api: ApiClient, private kycStore: KycStore) {
  }

  /** beware kyc must never be null */
  public kyc(): Observable<Kyc> {
    return this.kycStore.kyc$.pipe(
      tap(stored => {
        if (!stored) {
          this.fetch();
        }
      }),
      filter(stored => !!stored)
    );
  }

  public async fetch(): Promise<void> {
    const kycs: Kyc[] = Object.values(await this.api.get<Kyc[]>(this.url))
      .sort((a, b) => sortDesc(a.createdAt, b.createdAt));

    if (!kycs || !kycs.length) {
      throw new HttpErrorResponse({error: new Error('Kyc not found')});
    }
    this.kycStore.saveKyc(kycs[0]);
  }

  public async create(): Promise<Kyc> {
    const kyc: Kyc = await this.api.post(this.url);
    if (kyc === undefined) {
      throw new HttpErrorResponse({error: new Error('Kyc not found')});
    }

    this.kycStore.saveKyc(kyc);
    return kyc;
  }

  public async update(command: UpdateKyc): Promise<void> {
    const kyc = command.kyc;
    kyc.answers = kyc.answers.filter((answer: KycAnswer) => answer.question.inputKind !== 'expected_invest');
    const snakeCommand: object = objKeysToSnakeCase(kyc);
    await this.api.put(this.url + '/' + kyc.id, snakeCommand);
    await this.fetch();
  }

  public async signKyc(command: SignKyc): Promise<void> {
    await this.api.post('sign-kyc', objKeysToSnakeCase(command));
  }

  public syncKyc(): Kyc | null {
    return this.kycStore.syncKyc();
  }

  public async waitFetchAndSync(): Promise<Kyc> {
    let kyc = this.syncKyc();
    if (!kyc) {
      await this.fetch();
      kyc = this.syncKyc();
    }
    return kyc;
  }

}
