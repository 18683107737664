import {Component, EventEmitter, Output} from '@angular/core';
import {ConfirmOption} from 'src/app/domain/function/type.helper';
import {WebComponent} from 'src/app/ui/webapp/component/web.component';

@Component({
  selector: 'app-confirm-repeat-kyc',
  templateUrl: 'confirm-repeat-kyc.component.html'
})
export class ConfirmRepeatKycComponent extends WebComponent {
  @Output() chosenOption: EventEmitter<ConfirmOption> = new EventEmitter<ConfirmOption>();
}
